(($) => {
  $(document).ready(function () {
    const $mobileMenuTrigger = $("#mobile-menu-open-trigger");
    $mobileMenuTrigger.on("click", () => {
      // add 'mobile-menu-opened' class to body;
      $("body").addClass("mobile-menu-opened");
    });

    const $offcanvasCloseTrigger = $("#mobile-menu-close-trigger");
    $offcanvasCloseTrigger.on("click", () => {
      // remove 'mobile-menu-opened' class to body;
      $("body").removeClass("mobile-menu-opened");
    });

    // 외부 클릭시 닫기
    $(".offcanvas-nav-container").click(function (event) {
      // 'offcanvas-inner' 요소를 클릭했는지 확인하기 위해 이벤트 대상을 검사합니다
      if (!$(event.target).closest(".offcanvas-inner").length) {
        // 'offcanvas-inner'가 클릭되지 않았을 때의 동작을 여기에 추가합니다
        $("body").removeClass("mobile-menu-opened");
      }
    });
  });
})(jQuery);

// accordion
(($) => {
  // Include jQuery library
  $(document).ready(function () {
    // '.toggle-sub-menu' 클릭 이벤트를 처리합니다
    $(".offcanvas-nav-container .menu-item.has-sub-menu > a").click(function (
      event
    ) {
      event.preventDefault(); // 기본 동작 방지
      // 클릭된 요소의 부모의 부모 요소인 '.menu-item'에서 '.sub-menu'를 찾아 토글 애니메이션을 적용합니다
      $(this).closest(".menu-item").find(".sub-menu").slideToggle();

      $(this).find(".svg-icon-down, .svg-icon-up").toggle();
    });
  });
})(jQuery);

// Login Modal
(($) => {
  document.addEventListener("DOMContentLoaded", function () {
    const $loginBtns = [
      ...(document.querySelectorAll(".login-button > a") ?? []),
    ];

    $loginBtns.forEach(($loginBtn) => {
      $loginBtn.addEventListener("click", function (e) {
        e.preventDefault();

        // 모바일에서는 메뉴를 닫고 모달을 띄웁니다
        const $mobileMenuTrigger = $("#mobile-menu-close-trigger");
        $mobileMenuTrigger.click();
        setTimeout(() => {
          const $loginModal = $(".login-modal");
          $loginModal.modal({
            fadeDuration: 100,
            closeText:
              '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
          });
        }, 400);
      });
    });
  });
})(jQuery);

// 사용자 이미지 preview
(($) => {
  document.addEventListener("DOMContentLoaded", function () {
    const imageInput = document.getElementById("profile_image");
    if (!imageInput) return;

    imageInput.addEventListener("change", function () {
      if (this.files && this.files[0]) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const $preview_container = document.querySelector(
            ".profile_image_preview_container"
          );
          $preview_container.classList.remove("hidden");

          const $preview = document.getElementById("profile_image_preview");
          $preview.src = e.target.result;

          const $placeholder = document.getElementById(
            "profile_image_placeholder"
          );
          $placeholder.classList.add("hidden");
        };

        reader.readAsDataURL(this.files[0]);
      }
    });

    // 버튼 클릭시 이미지 변경
    const $profileImageBtn = document.querySelector(
      ".profile_image_upload_btn"
    );
    if (!$profileImageBtn) return;
    $profileImageBtn.addEventListener("click", function () {
      imageInput.click();
    });

    // x버튼 클릭시 이미지 삭제
    const $profileImageDeleteBtn = document.querySelector(
      ".remove_profile_image_btn"
    );
    if (!$profileImageDeleteBtn) return;
    $profileImageDeleteBtn.addEventListener("click", function () {
      const $preview_container = document.querySelector(
        ".profile_image_preview_container"
      );
      $preview_container.classList.add("hidden");

      const $preview = document.getElementById("profile_image_preview");
      $preview.src = "";

      const $placeholder = document.getElementById("profile_image_placeholder");
      $placeholder.classList.remove("hidden");
    });
  });
})(jQuery);

// 회원 정보 수정 api
(($) => {
  $(document).ready(function () {
    $("#my_account_form").on("submit", function (e) {
      e.preventDefault();

      const $indicator = $(".loading-indicator");
      $indicator.removeClass("hidden");

      const formData = new FormData(this);
      formData.append("action", "save_my_account");

      // mobile_phone_1, mobile_phone_2, mobile_phone_3 합치기
      const mobilePhone1 = formData.get("mobile_phone_1");
      const mobilePhone2 = formData.get("mobile_phone_2");
      const mobilePhone3 = formData.get("mobile_phone_3");
      let mobilePhone = "";
      if (mobilePhone1 && mobilePhone2 && mobilePhone3) {
        mobilePhone = `${mobilePhone1}${mobilePhone2}${mobilePhone3}`;
      }
      formData.set("mobile_phone", mobilePhone);

      // 집 전화는 합치지 않습니다
      const homePhone1 = formData.get("home_phone_1");
      const homePhone2 = formData.get("home_phone_2");
      if (homePhone1) {
        formData.set("home_phone_1", homePhone1);
      }
      if (homePhone2) {
        formData.set("home_phone_2", homePhone2);
      }

      // 직장 전화는 합치지 않습니다
      const workPhone1 = formData.get("work_phone_1");
      const workPhone2 = formData.get("work_phone_2");
      if (workPhone1) {
        formData.set("work_phone_1", workPhone1);
      }
      if (workPhone2) {
        formData.set("work_phone_2", workPhone2);
      }

      const native_password = formData.get("native_password");

      $.ajax({
        url: ex_global_obj.ajax_url,
        type: "POST",
        data: formData,
        contentType: false, // Required for FormData
        processData: false, // Required for FormData
        success: function (response) {
          const isSuccessful = response.success;
          if (!isSuccessful) {
            $indicator.addClass("hidden");
            alert(response.data);
            return;
          }
          $indicator.addClass("hidden");
          if (native_password) {
            alert("회원 정보가 수정되었습니다. 다시 로그인해주세요.");
          } else {
            alert("회원 정보가 수정되었습니다.");
          }
          window.location.href = ex_global_obj.home_url + "/마이페이지";
        },
        error: function (error) {
          console.dir(error);
          $indicator.addClass("hidden");
          alert("회원 정보 수정에 문제가 발생했습니다.");
        },
      });
    });
  });
})(jQuery);

// 행사 사진 다운로드 버튼 추가
(($) => {
  $(document).ready(function () {
    // Select all gallery items
    $("#kboard-ocean-gallery-document .content-view").each(function () {
      const $img = $(this).find("img");
      // wrap img with div tag
      $img.wrap('<div class="kboard-img-container"></div>');
      const itemLink = $img.attr("src");
      const $thumbnail = $img.parent();

      // Create the download button
      const $downloadButton = $("<a>", {
        text: "다운로드", // Text for the button
        href: itemLink, // Set the download link to the modified image
        class: "download-button", // Add class for styling
        target: "_blank",
        download: "",
      });

      // Append the button to the thumbnail
      $thumbnail.append($downloadButton);
    });
  });
})(jQuery);

// 행사 사진 리스트에 + svg 아이콘 추가
(($) => {
  const $thumbnailList = $(
    "#kboard-ocean-gallery-list .kboard-gallery-thumbnail"
  );
  const svgString =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>';

  // Add the svg icon to each thumbnail and make it centered
  $thumbnailList.each(function () {
    // append svgString with layer
  });
})(jQuery);

// 회원 정보 수정 페이지에서 비밀번호 보기 버튼 toggle
// 비밀번호 유효성 검사
(($) => {
  document.addEventListener("DOMContentLoaded", () => {
    // Select the password input and the toggle checkbox
    const passwordInput = document.getElementById("native_password");
    const toggleCheckbox = document.getElementById("toggle-password");

    if (!passwordInput || !toggleCheckbox) return;

    // Password validation function
    function validatePassword(password) {
      // Regex for password validation: 8-16 characters, including letters and numbers
      const regex = /^(?=.*[a-z])(?=.*\d)[a-z\d]{8,16}$/;
      return regex.test(password);
    }

    const originalBorderColor = passwordInput.style.borderColor;
    const originalBorderWidth = passwordInput.style.borderWidth;

    // Add event listener to validate password on input change
    passwordInput.addEventListener("input", function () {
      if (validatePassword(passwordInput.value)) {
        // If valid, you can set some visual indication, like changing the border color
        passwordInput.style.borderColor = originalBorderColor;
        passwordInput.style.borderWidth = originalBorderColor;
      } else {
        passwordInput.style.borderColor = "red";
        passwordInput.style.borderWidth = "3px";
      }
    });

    toggleCheckbox.addEventListener("change", (event) => {
      if (event.target.checked) {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    });
  });
})(jQuery);

// .login-modal 내부에 .rwmb-error의 textContent가 비어있지 않다면, login-modal을 띄웁니다
(($) => {
  document.addEventListener("DOMContentLoaded", () => {
    const $loginModal = $(".login-modal");
    const $loginModalError = $loginModal.find(".rwmb-error");
    if ($loginModalError.text().trim() !== "") {
      $loginModal.modal({
        fadeDuration: 100,
        closeText:
          '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"> </line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
      });
    }
  });
})(jQuery);

// 비밀번호 찾기를 클릭했다면, 모달을 다시 띄워줍니다.
jQuery(($) => {
  const $loginModal = $(".login-modal");
  const $loginModalError = $loginModal.find(".rwmb-info");
  if ($loginModalError.text().trim() !== "") {
    $loginModal.modal({
      fadeDuration: 100,
      closeText:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"> </line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
    });
  }
});

// category option명 변경
(($) => {
  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
      const categorySelect = document.querySelector("#kboard-tree-category-1");
      if (!categorySelect) return;

      const categoryOption = categorySelect.querySelector(
        "#kboard-tree-category-1 option:first-child"
      );
      if (!categoryOption) return;

      categoryOption.textContent = "소재 국가 선택";
      categorySelect.style.opacity = "1";
    }, 200);
  });
})(jQuery);

// 모바일 폰 인풋 설정
(($) => {
  document.addEventListener("DOMContentLoaded", () => {
    const mobilePhoneInput1 = document.querySelector("#mobile_phone_1");
    const mobilePhoneInput2 = document.querySelector("#mobile_phone_2");
    const mobilePhoneInput3 = document.querySelector("#mobile_phone_3");

    if (!mobilePhoneInput1 || !mobilePhoneInput2 || !mobilePhoneInput3) return;

    // only accept numbers
    mobilePhoneInput1.addEventListener("input", function () {
      this.value = this.value.replace(/[^0-9]/g, "");
      if (this.value.length > 3) {
        this.value = this.value.slice(0, 3);
      }
    });
    mobilePhoneInput2.addEventListener("input", function () {
      this.value = this.value.replace(/[^0-9]/g, "");
      // max length is 4
      if (this.value.length > 4) {
        this.value = this.value.slice(0, 4);
      }
    });
    mobilePhoneInput3.addEventListener("input", function () {
      this.value = this.value.replace(/[^0-9]/g, "");
      // max length is 4
      if (this.value.length > 4) {
        this.value = this.value.slice(0, 4);
      }
    });
  });
})(jQuery);

// 집전화 인풋 설정
(($) => {
  document.addEventListener("DOMContentLoaded", () => {
    const homePhoneInput2 = document.querySelector("#home_phone_2");
    if (!homePhoneInput2) return;

    // only accept numbers
    homePhoneInput2.addEventListener("input", function () {
      this.value = this.value.replace(/[^0-9]/g, "");
      // max length is 10
      if (this.value.length > 10) {
        this.value = this.value.slice(0, 10);
      }
    });
  });
})(jQuery);

// Login form 구조 변경하기
jQuery(($) => {
  // change placeholder
  const usernameInput = document.getElementById("user_login");
  const passwordInput = document.getElementById("user_pass");

  // Set placeholders for the input fields
  usernameInput.setAttribute("placeholder", "아이디");
  passwordInput.setAttribute("placeholder", "비밀번호");

  // placeholder 세팅은 브라우저 크기에 관련 없이 실행합니다

  // 브라우저 가로 길이가 990px 이상인 경우에만 실행합니다
  if (window.innerWidth < 990) return;

  // Select the form element
  const form = document.getElementById("login-form");
  if (!form) return;

  // Create a fragment to hold the new structure without losing the hidden fields
  const newStructure = document.createDocumentFragment();

  // Create new containers for the modified structure
  const formContainer = document.createElement("div");
  formContainer.className = "login-form-container tw-flex tw-flex-col tw-gap-2";

  const row1 = document.createElement("div");
  row1.className = "row tw-flex tw-gap-2";

  const leftColumn = document.createElement("div");
  leftColumn.className = "left tw-flex-1";

  const rightColumn = document.createElement("div");
  rightColumn.className = "right";

  const row2 = document.createElement("div");
  row2.className = "row";

  // Append existing hidden input fields to the fragment
  Array.from([
    ...(form.querySelectorAll('input[type="hidden"]') ?? []),
  ]).forEach((hiddenInput) => {
    newStructure.appendChild(hiddenInput);
  });

  // Move login and password fields to the left column
  const userLoginField = document.querySelector(
    ".rwmb-field.rwmb-text-wrapper"
  );
  const passwordField = document.querySelector(
    ".rwmb-field.rwmb-password-wrapper"
  );

  leftColumn.appendChild(userLoginField);
  leftColumn.appendChild(passwordField);

  // Move submit button to the right column
  const submitButton = document.querySelector(
    ".rwmb-field.rwmb-button-wrapper"
  );
  rightColumn.appendChild(submitButton);

  // Add left and right columns to the first row
  row1.appendChild(leftColumn);
  row1.appendChild(rightColumn);

  // Move 'Remember Me' checkbox and 'Lost Password' link to the second row
  const rememberMeCheckbox = document.querySelector(
    ".rwmb-field.rwmb-checkbox-wrapper"
  );
  const lostPasswordLink = document.querySelector(
    ".rwmb-field.rwmb-custom_html-wrapper"
  );
  row2.appendChild(rememberMeCheckbox);
  row2.appendChild(lostPasswordLink);

  // Append rows to the form container
  formContainer.appendChild(row1);
  formContainer.appendChild(row2);

  // Add the new structure to the fragment
  newStructure.appendChild(formContainer);

  // Replace the original form content with the new structure
  form.innerHTML = "";
  form.appendChild(newStructure);

  console.log("form modification is done");
});

// 뒤로가기 버튼
jQuery(($) => {
  const $backBtn = $(".go-to-back-btn-at-privacy");
  if (!$backBtn) return;

  $backBtn.on("click", function () {
    window.history.back();
  });
});
